import { addMonths, format } from "date-fns";

const parseOrZero = (num) => {
  return num ? parseFloat(num) : 0;
};

const calcInterestAmount = (purchasePrice, months, monthlyPayment) => {
  return Math.abs(
    parseOrZero(purchasePrice) -
      parseOrZero(months) * parseOrZero(monthlyPayment)
  ).toFixed(2);
};

const calcMonthlyPayment = (interestRate, months, loanAmount) => {
  const R = parseOrZero(interestRate) / 1200;
  const n = parseOrZero(months);
  const pv = parseOrZero(loanAmount);
  return ((pv * R) / (1 - Math.pow(1 + R, n * -1))).toFixed(2);
};

const calcLoanAmount = (serviceAmount, downPayment) => {
  return (parseOrZero(serviceAmount) - parseOrZero(downPayment)).toFixed(2);
};

const calcAmountFinanced = (unpaidBalance, financeCharge) => {
  return parseOrZero(unpaidBalance).toFixed(2);
  // return (parseOrZero(unpaidBalance) - parseOrZero(financeCharge)).toFixed(2);
};

const calcFinanceCharge = (
  unpaidBalance,
  originationFeePercent,
  originationFeeMax
) => {
  let originationFee = !originationFeePercent
    ? 0
    : parseOrZero(unpaidBalance) * (parseOrZero(originationFeePercent) / 100);

  if (originationFee > originationFeeMax) {
    originationFee = originationFeeMax;
  }

  return originationFee.toFixed(2);
};

const calcPaymentsTotal = (term, monthlyPayment) => {
  return (parseOrZero(term) * parseOrZero(monthlyPayment)).toFixed(2);
};
const calcGrandTotal = (paymentsTotal, downPayment, financeCharge) => {
  return (
    parseOrZero(paymentsTotal) +
    parseOrZero(downPayment) +
    parseOrZero(financeCharge)
  ).toFixed(2);
};

const calcMonthlyInterestAmount = (currentBalance, apr) => {
  const interest = (parseOrZero(currentBalance) * parseOrZero(apr)) / 100 / 12;
  const rounded = Math.round(interest * 100) / 100;
  return rounded.toFixed(2);
};

const calcMonthlyPrincipalAmount = (
  monthlyInterestAmount,
  totalPaidForMonth
) => {
  const principal =
    parseOrZero(totalPaidForMonth) - parseOrZero(monthlyInterestAmount);
  const rounded = Math.round(principal * 100) / 100;
  return rounded.toFixed(2);
};

export const getDefaults = (values = {}) => {
  // copy the values obect that was passed in

  const defaults = {
    extraPayments: {},

    ...values,
  };

  return defaults;
};

export const getValue = (state, value, values) => {
  if (value) {
    return state[value];
  } else if (values) {
    return values.reduce((acc, cur) => acc + parseOrZero(state[cur]), 0);
  } else {
    return 0;
  }
};

export const calculateAmortization = (v) => {
  /**
   * calculate:
   *  * month (date of payment)
   *  * payment amount (would be the same every month)
   *  * extra amount
   *  * total paid for month
   *  * remaining balance
   *  * amount going towards principal
   *  * amount going towards interest
   *  * LTV (loan-to-value)
   */
  const {
    term,
    monthlyPayment,
    amountFinanced,
    startDate: startDateString,
    apr,
    serviceAmount,
    extraPayments,
  } = v;

  const newDate = new Date(startDateString);
  const startDate = new Date(
    newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const payments = [...new Array(parseInt(term))]
    .map((_, monthIndex) => monthIndex)
    .reduce((pastMonthlyPayments, currentPaymentMonthIndex) => {
      const currentBalance =
        currentPaymentMonthIndex === 0
          ? amountFinanced
          : pastMonthlyPayments.slice(-1)[0].remainingBalance;

      if (parseFloat(currentBalance) <= 0) {
        return pastMonthlyPayments;
      }

      const paymentDate = addMonths(startDate, currentPaymentMonthIndex);
      const month = monthNames[paymentDate.getMonth()];
      const year = paymentDate.getFullYear();

      const extraPaymentAmount = extraPayments[currentPaymentMonthIndex] || 0;
      const totalPaidForMonth = (
        parseOrZero(monthlyPayment) + parseOrZero(extraPaymentAmount)
      ).toFixed(2);

      const monthlyInterestAmount = calcMonthlyInterestAmount(
        currentBalance,
        apr
      );
      const monthlyPrincipalAmount = calcMonthlyPrincipalAmount(
        monthlyInterestAmount,
        totalPaidForMonth
      );
      let remainingBalance = (
        parseOrZero(currentBalance) - parseOrZero(monthlyPrincipalAmount)
      ).toFixed(2);

      if (parseFloat(remainingBalance) < 0) {
        remainingBalance = (0).toFixed(2);
      }

      const loanToValue = ((remainingBalance / serviceAmount) * 100).toFixed(2);

      const paymentDetails = {
        paymentNumber: currentPaymentMonthIndex + 1,
        month,
        year,
        paymentAmount: monthlyPayment,
        extraPaymentAmount,
        totalPaidForMonth,
        remainingBalance,
        monthlyInterestAmount,
        monthlyPrincipalAmount,
        loanToValue,
      };

      pastMonthlyPayments.push(paymentDetails);

      return pastMonthlyPayments;
    }, []);

  return payments;
};

export const calculateLoan = (v) => {
  v.unpaidBalance = calcLoanAmount(v.serviceAmount, v.downPayment);

  v.financeCharge = calcFinanceCharge(
    v.unpaidBalance,
    v.originationFeePercent,
    v.originationFeeMax
  );

  v.amountFinanced = calcAmountFinanced(v.unpaidBalance, v.financeCharge);

  const monthlyPayment = calcMonthlyPayment(v.apr, v.term, v.amountFinanced);
  v.monthlyPayment = isNaN(monthlyPayment) ? null : monthlyPayment;

  v.interestAmount = calcInterestAmount(
    v.amountFinanced,
    v.term,
    v.monthlyPayment
  );

  v.paymentsTotal = calcPaymentsTotal(v.term, v.monthlyPayment);
  v.grandTotal = calcGrandTotal(
    v.paymentsTotal,
    v.downPayment,
    v.financeCharge
  );

  return v;
};
