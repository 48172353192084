import React from "react";
import FormPageTemplate from "../components/FormPageTemplate";
import {
  calculateLoan,
  calculateAmortization,
  getDefaults,
} from "../utils/loanFuncs/consumerLoanFuncs";
import ResultsForm from "../components/ResultsForms";
import LoanPageStyles from "../styles/LoanPageStyles";
import SEO from "../components/SEO";

const inputs = [
  // each nested array is a fieldset
  [
    {
      name: "loanName",
      label: "Loan Name",
      required: true,
      placeholder: "",
      autocomplete: "",
      fullWidth: true,
    },
  ],
  [
    {
      name: "serviceAmount",
      label: "Service Amount",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "term",
      label: "Term (months)",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "downPayment",
      label: "Down Payment",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "apr",
      label: "APR",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "originationFeePercent",
      label: "Origination Fee Percent",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "originationFeeMax",
      label: "Max Origination Fee",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
  ],
];

const computedFields = [
  { label: "APR", field: "apr", isPercentage: true },
  { label: "Finance Charge", field: "financeCharge", isDollarAmount: true },
  { label: "Down Payment", field: "downPayment", isDollarAmount: true },
  { label: "Amount Financed", field: "amountFinanced", isDollarAmount: true },
  {
    label: "Monthly Payments Total",
    field: "paymentsTotal",
    isDollarAmount: true,
  },
  { label: "Grand Total", field: "grandTotal", isDollarAmount: true },
];

const amortizationColumnDefs = [
  {
    label: "#",
    field: "paymentNumber",
    style: { width: 50 },
  },
  {
    label: "Month",
    field: "month",
  },
  {
    label: "Year",
    field: "year",
  },
  // {
  //   label: "Payment",
  //   field: "paymentAmount",
  //   isDollarAmount: true,
  // },
  {
    label: "Interest",
    field: "monthlyInterestAmount",
    isDollarAmount: true,
  },
  {
    label: "Principal",
    field: "monthlyPrincipalAmount",
    isDollarAmount: true,
  },
  {
    label: "Total",
    field: "totalPaidForMonth",
    isDollarAmount: true,
  },
  {
    label: "Remaining",
    field: "remainingBalance",
    isDollarAmount: true,
  },
  {
    label: "LTV",
    field: "loanToValue",
    isPercentage: true,
  },
];

const formDefaults = {
  loanName: '',
  
  serviceAmount: 4250,
  downPayment: 4250 * 0.25,

  term: 6,
  apr: 20.99,

  originationFeePercent: 2,
  originationFeeMax: 199,

  startDate: "2021-07-28",
};

const formOptions = {
  requiredFields: inputs
    .flat()
    .filter((i) => i.required)
    .map((i) => i.name),
  calculateLoan,
  calculateAmortization,
  getDefaults,
};

export default function ({ data }) {
  return (
    <>
      <SEO title={"Consumer Loan"} />
      <LoanPageStyles>
        <FormPageTemplate
          formKey="consumer-loan"
          formDefaults={formDefaults}
          formOptions={formOptions}
          style={{ marginBottom: 50 }}
          inputs={inputs}
          resultsFormProps={{
            computedFields,
            amortizationColumnDefs,
          }}
          resultsForm={ResultsForm}
        />
      </LoanPageStyles>
    </>
  );
}
